import React from 'react';
import '../index.css';

const Social = () => {
     return ( 
         <div className="socialsContainer">
            <ul className='socialsFlex'> 

               <li className='socialsBox'>
                  <div className='socialsIcons'>
                     <a href="https://github.com/AkinAce"
                     target="_blank" rel="noreferrer"
                     title= {"Go to Akintunde Sallam's Github Page"}>              
                        <svg viewBox="0 0 128 128"><g fill="#181616"><path fill-rule="evenodd" clip-rule="evenodd" d="M64 5.103c-33.347 0-60.388 27.035-60.388 60.388 0 26.682 17.303 49.317 41.297 57.303 3.017.56 4.125-1.31 4.125-2.905 0-1.44-.056-6.197-.082-11.243-16.8 3.653-20.345-7.125-20.345-7.125-2.747-6.98-6.705-8.836-6.705-8.836-5.48-3.748.413-3.67.413-3.67 6.063.425 9.257 6.223 9.257 6.223 5.386 9.23 14.127 6.562 17.573 5.02.542-3.903 2.107-6.568 3.834-8.076-13.413-1.525-27.514-6.704-27.514-29.843 0-6.593 2.36-11.98 6.223-16.21-.628-1.52-2.695-7.662.584-15.98 0 0 5.07-1.623 16.61 6.19C53.7 35 58.867 34.327 64 34.304c5.13.023 10.3.694 15.127 2.033 11.526-7.813 16.59-6.19 16.59-6.19 3.287 8.317 1.22 14.46.593 15.98 3.872 4.23 6.215 9.617 6.215 16.21 0 23.194-14.127 28.3-27.574 29.796 2.167 1.874 4.097 5.55 4.097 11.183 0 8.08-.07 14.583-.07 16.572 0 1.607 1.088 3.49 4.148 2.897 23.98-7.994 41.263-30.622 41.263-57.294C124.388 32.14 97.35 5.104 64 5.104z"></path><path d="M26.484 91.806c-.133.3-.605.39-1.035.185-.44-.196-.685-.605-.543-.906.13-.31.603-.395 1.04-.188.44.197.69.61.537.91zm2.446 2.729c-.287.267-.85.143-1.232-.28-.396-.42-.47-.983-.177-1.254.298-.266.844-.14 1.24.28.394.426.472.984.17 1.255zM31.312 98.012c-.37.258-.976.017-1.35-.52-.37-.538-.37-1.183.01-1.44.373-.258.97-.025 1.35.507.368.545.368 1.19-.01 1.452zm3.261 3.361c-.33.365-1.036.267-1.552-.23-.527-.487-.674-1.18-.343-1.544.336-.366 1.045-.264 1.564.23.527.486.686 1.18.333 1.543zm4.5 1.951c-.147.473-.825.688-1.51.486-.683-.207-1.13-.76-.99-1.238.14-.477.823-.7 1.512-.485.683.206 1.13.756.988 1.237zm4.943.361c.017.498-.563.91-1.28.92-.723.017-1.308-.387-1.315-.877 0-.503.568-.91 1.29-.924.717-.013 1.306.387 1.306.88zm4.598-.782c.086.485-.413.984-1.126 1.117-.7.13-1.35-.172-1.44-.653-.086-.498.422-.997 1.122-1.126.714-.123 1.354.17 1.444.663zm0 0"></path></g></svg>
                     </a>
                  </div>             
               </li>

               <li className='socialsBox'>
                  <div className='socialsIcons'>
                     <a href="https://twitter.com/the_akintunde"
                     target="_blank" rel="noreferrer"
                     title= {"Go to Akintunde Sallam's Twitter Page"}>                 
                        <svg  viewBox="0 0 20 20"><path fill="none" d="M18.258,3.266c-0.693,0.405-1.46,0.698-2.277,0.857c-0.653-0.686-1.586-1.115-2.618-1.115c-1.98,0-3.586,1.581-3.586,3.53c0,0.276,0.031,0.545,0.092,0.805C6.888,7.195,4.245,5.79,2.476,3.654C2.167,4.176,1.99,4.781,1.99,5.429c0,1.224,0.633,2.305,1.596,2.938C2.999,8.349,2.445,8.19,1.961,7.925C1.96,7.94,1.96,7.954,1.96,7.97c0,1.71,1.237,3.138,2.877,3.462c-0.301,0.08-0.617,0.123-0.945,0.123c-0.23,0-0.456-0.021-0.674-0.062c0.456,1.402,1.781,2.422,3.35,2.451c-1.228,0.947-2.773,1.512-4.454,1.512c-0.291,0-0.575-0.016-0.855-0.049c1.588,1,3.473,1.586,5.498,1.586c6.598,0,10.205-5.379,10.205-10.045c0-0.153-0.003-0.305-0.01-0.456c0.7-0.499,1.308-1.12,1.789-1.827c-0.644,0.28-1.334,0.469-2.06,0.555C17.422,4.782,17.99,4.091,18.258,3.266"></path></svg>
                     </a>           
                  </div>             
               </li>

               <li className='socialsBox'>
                  <div className='socialsIcons'>
                     <a href="https://www.linkedin.com/in/akintunde-sallam/"
                     target="_blank" rel="noreferrer"
                     title= {"Go to Akintunde Sallam's Linkedin Page"}>                                 
                        <svg viewBox="0 0 128 128"><path d="M116 3H12a8.91 8.91 0 00-9 8.8v104.42a8.91 8.91 0 009 8.78h104a8.93 8.93 0 009-8.81V11.77A8.93 8.93 0 00116 3zM39.17 107H21.06V48.73h18.11zm-9-66.21a10.5 10.5 0 1110.49-10.5 10.5 10.5 0 01-10.54 10.48zM107 107H88.89V78.65c0-6.75-.12-15.44-9.41-15.44s-10.87 7.36-10.87 15V107H50.53V48.73h17.36v8h.24c2.42-4.58 8.32-9.41 17.13-9.41C103.6 47.28 107 59.35 107 75z"></path></svg>
                     </a>
                  </div>             
               </li>

               <li className='socialsBox'>
                  <div className='socialsIcons'>
                     <a href="https://www.behance.net/akintundesallam/"
                     target="_blank" rel="noreferrer"
                     title= {"Go to Akintunde Sallam's Behance Page"}>                                         
                        <svg viewBox="0 0 128 128"><g fill="#1d1d1b"><path d="M48.53 48.26c0-7.16-4.87-7.16-4.87-7.16h-20v15.35h18.76c3.24 0 6.11-1.04 6.11-8.19zM43.66 67.06h-20v18.38h19.1c2.87-.08 8.18-1 8.18-8.94-.03-9.5-7.28-9.44-7.28-9.44zM94.93 56.45c-10.63 0-12.11 10.6-12.11 10.6h22.6s.14-10.6-10.49-10.6z"></path><path d="M111.25 2.16h-94.5A14.59 14.59 0 002.16 16.75v94.49a14.59 14.59 0 0014.59 14.59h94.49a14.59 14.59 0 0014.59-14.59V16.75a14.59 14.59 0 00-14.58-14.59zM79.86 32.92h28.38v8.48H79.86zM65.51 77.36c0 21-21.85 20.31-21.85 20.31H7.86v-68.8h35.8c10.88 0 19.47 6 19.47 18.32s-10.5 13.09-10.5 13.09c13.84 0 12.88 17.08 12.88 17.08zm53.09-1.12H83c0 12.78 12.11 12 12.11 12 11.43 0 11-7.4 11-7.4h12.09c0 19.64-23.54 18.29-23.54 18.29-28.25 0-26.43-26.3-26.43-26.3s0-26.43 26.43-26.43c27.85-.02 23.95 29.84 23.95 29.84z"></path></g></svg>         
                     </a>
                  </div>             
               </li>

            </ul> 
         </div>    
      );
   } 
export default Social;